import React, { useContext, useState } from "react";
import CategoriesContext from "./categoriesContext";
import AuthContext from "../auth/authContext";

const afterPartyCategories_DUMMY_DATA = {
  count: 8,
  categories: [
    {
      _id: 1,
      name: "Happy Hours",
      club: {
        _id: "1",
        name: "Blackout",
      },
      image: [
        {
          _id: 123,
          location:
            "https://media.istockphoto.com/vectors/happy-hour-neon-sign-on-dark-background-vector-id1182436652?k=20&m=1182436652&s=170667a&w=0&h=RRO-8k5jXhKHHBoUa0XAhBgU4QF-trtGA2GjemDeISo=",
        },
      ],
    },
  ],
};

const CategoriesState = (props) => {

  const [eventCategory, setEventCategory] = useState({
    name:"",
    description:"",
    image:null,
    icon:null,
  })
  const [eventCategories, setEventCategories] = useState([]);
  const [blogCategories, setBlogCategories] = useState([]);
  const ContextAuth = useContext(AuthContext);
  const { token } = ContextAuth;
  const [afterPartyCategories, setAfterPartyCategories] = useState({
    count: 0,
    categories: [],
  });
  const fetchEventCategories = async (ofType) => {
    // setEventCategories(eventCategories_DUMMY_DATA);
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/category/${ofType}/all`,
      {
        method: "GET",

        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();
    console.log(json);
    // setEventCategories(eventCategories_DUMMY_DATA);
    setEventCategories(json);
  };
  const fetchEventCategory= async (id,ofType) => {
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/category/${ofType}/${id}`,
      {
        method: "GET",

        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();
    console.log(json);
    
    return json;
  };
  const fetchBlogCategories = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/category/blog-category/all`,
      {
        method: "GET",

        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();
    console.log(json);
    setBlogCategories(json);
  };
  const fetchBlogCategory= async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/category/blogs/${id}`,
      {
        method: "GET",

        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();
    console.log(json);
    
    return json;
  };
  const addBlogCategories = async(content) => {
    const response = await fetch(`${process.env.REACT_APP_HOST}/category/blogs`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify(content),
    });
    const json = await response.json();
    console.log(json);  
  };
  const updateBlogCategories = async(content) => {
    const response = await fetch(`${process.env.REACT_APP_HOST}/category/blogs/${content._id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      body: JSON.stringify(content),
    });
    const json = await response.json();
    console.log(json);  
  };

  const fetchAfterPartyCategories = () => {
    setAfterPartyCategories(afterPartyCategories_DUMMY_DATA);
  };

  const createEventCategory = async (content,ofType) => {
    const response = await fetch(`${process.env.REACT_APP_HOST}/category/${ofType}`, {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(content),
    });
    await response.json();
  };
  const updateEventCategory = async(content, ofType)=>{
    const response = await fetch(`${process.env.REACT_APP_HOST}/category/${ofType}/${content._id}`,{
      method:"PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(content),
    })
    await response.json();
  }

  const deleteEventCategory = async (id,ofType) => {
    const response = await fetch(`${process.env.REACT_APP_HOST}/category/${ofType}/${id}`, {
        method: 'DELETE',

        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`,
        },
    });
    const json = response.json();
    console.log(json);

    console.log("deleting a event category with id " +id)
}
  return (
    <CategoriesContext.Provider
      value={{
        eventCategory,
        setEventCategory,
        eventCategories,
        afterPartyCategories,
        fetchEventCategories,
        fetchEventCategory,
        fetchAfterPartyCategories,
        blogCategories,
        addBlogCategories,
        updateBlogCategories,
        fetchBlogCategory,
        fetchBlogCategories,
        createEventCategory,
        updateEventCategory,
        deleteEventCategory
      }}
    >
      {props.children}
    </CategoriesContext.Provider>
  );
};

export default CategoriesState;
