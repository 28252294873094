import React, { useState } from "react";
import DatingContext from "./datingContext";

const DatingState = (props) => {
  const host = process.env.REACT_APP_HOST;
  const [isBookingDate, setIsBookingDate] = useState(false)
  const [dateUser, setDateUser] = useState(null)
  const [dateRequest, setDateRequest] = useState({
    orderId: "",
    userImage: "",
    firstName: "",
    lastName: "",
    clubName: "",
    orderDetails: "",
    DOB: "",
    bio: "",
    clubId: "",
    eventId: "",
    userId: "",
    requestTime:"",
  });
  const EnableBookDate=(user)=>{
    setIsBookingDate(true)
    setDateUser(user)
  }
  return (
    <DatingContext.Provider
      value={{
        isBookingDate,
        setIsBookingDate,dateUser,
        setDateUser,
        EnableBookDate,
        dateRequest,
        setDateRequest
      }}
    >
      {props.children}
    </DatingContext.Provider>
  );
};

export default DatingState;
