import React, { useContext, useState } from "react";
import LocationContext from "./locationContext";
import AuthContext from "../auth/authContext";
const LocationState = (props) => {
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [city, setCity] = useState("");
  const [area, setArea] = useState("");
  const ContextAuth = useContext(AuthContext);
  const { token } = ContextAuth;
  const fetchCities = async () => {
    const response = await fetch(`${process.env.REACT_APP_HOST}/city`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    console.log(json);
    setCities(json);
    // setCities(cities_DUMMY_DATA);
  };
  const fetchCityById = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/city/id/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();
    console.log(json);

    return json;
  };
  const fetchAreaById = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/area/id/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();
    console.log(json);

    return json;
  };

  const fetchAreas = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/area/cityid/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();
    console.log(json);
    setAreas(json);
  };

  const fetchALLAreas = async () => {
    const response = await fetch(`${process.env.REACT_APP_HOST}/area`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    console.log(json);
    setAreas(json);
  };

  const updateArea = async (content, id) => {
    const response = await fetch(`${process.env.REACT_APP_HOST}/area/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(content),
    });
    const json = await response.json();
    console.log(json);
  };

  const deleteArea = async (id) => {
    const response = await fetch(`${process.env.REACT_APP_HOST}/area/${id}`, {
      method: "DELETE",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const json = response.json();
    console.log(json);

    console.log("deleting a city with id " + id);
  };

  return (
    <LocationContext.Provider
      value={{
        cities,
        fetchCities,
        areas,
        fetchAreas,
        fetchALLAreas,
        fetchAreaById,
        updateArea,
        deleteArea,
        fetchCityById,

        city,
        setCity,
        area,
        setArea,
      }}
    >
      {props.children}
    </LocationContext.Provider>
  );
};

export default LocationState;
