import React, { useContext, useState } from "react";
import ImageUploadContext from "./imageUploadContext";
import AuthContext from "../auth/authContext";

const ImageUploadState = (props) => {
  const host = process.env.REACT_APP_HOST;
  const ContextAuth = useContext(AuthContext);
  const { token } = ContextAuth;
  // Upload File
  const uploadFile = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await fetch(`${host}/images/post`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      const result = await response.json();
      return result;
    } catch (e) {
      console.log(e);
    }
  };
  const uploadFileGetUrl = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await fetch(`${host}/images/post`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      const result = await response.json();
      console.log(result);
      return result.images[0].location;
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <ImageUploadContext.Provider
      value={{
        uploadFile,
        uploadFileGetUrl,
      }}
    >
      {props.children}
    </ImageUploadContext.Provider>
  );
};

export default ImageUploadState;
