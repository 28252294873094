import React, { useContext, useState } from "react";
import ClubsContext from "./clubsContext";
import AuthContext from "../auth/authContext";

const ClubsState = (props) => {
  const host = process.env.REACT_APP_HOST;
  const ContextAuth = useContext(AuthContext);
  const { token } = ContextAuth;
  const [clubs, setClubs] = useState({
    count: 0,
    clubs: [],
  });
  const [club, setClub] = useState({
    name: "",
    images: [],
    timings: [],
    contact_no: [],
    address: "",
    // coordinates:{
    //   long:"",
    //   lat:""
    // },
    coordinates: "",
    description: "",
    views: "",
    likes: "",
    dates: [],
    features: [],
    coverImage: "",
  });

  
  const [totalPrice, setTotalPrice] = useState(0);
  const optionInitialValue = {
    title: "",
    price: 0,
    quantity: 0,
    totalTickets: 0,
    ticketsLeft: 0,
    discountPercent: 0,
    isBid_Order: false
  };

  const [orderDetails, setorderDetails] = useState({
    date: null,
    option1: optionInitialValue,
    option2: optionInitialValue,
    option3: optionInitialValue,
    option4: optionInitialValue,
    option5: optionInitialValue,
    refundTime: "",
    isBid_Order: optionInitialValue,
    datedUserId: "",
  });
  const [clubsWithoutPage, setClubsWithoutPage] = useState([]);
  const fetchClubs = async () => {
    try {
      const response = await fetch(`${host}/club`);
      const parsedResponse = await response.json();
      console.log(parsedResponse)
      setClubs(parsedResponse);
    } catch (err) {
      console.log(err);
    }
  };
  const fetchClubsByVendor = async () => {
    try {
      const response = await fetch(`${host}/fetchclub/byvendor`,{
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("vendorToken")}`,
        }
      });
      const parsedResponse = await response.json();
      console.log(parsedResponse)
      setClubs(parsedResponse);
    } catch (err) {
      console.log(err);
    }
  };
  const fetchClub = async(id) => {
    const response = await fetch(`${host}/club/${id}`);
    const parsedResponse = await response.json();

    // setClub(club_DUMMY_DATA);
    // setClub(parsedResponse);
    return parsedResponse
  };
  const fetchClubAdmin = async(id) => {
    const response = await fetch(`${host}/club/admin/${id}`);
    const parsedResponse = await response.json();

    // setClub(club_DUMMY_DATA);
    // setClub(parsedResponse);
    return parsedResponse
  };

  //Adding Club
  const addClub = async (content) => {
    const response = await fetch(`${host}/club`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
      },
      body: JSON.stringify(content),
    });
    // console.log(content);
    const addedClub = await response.json();
    if(response.status===200 || response.status===201){
      return "success"
    }
    else {return"fail"}
  };
  //Adding Club
  const updateClub = async (content,id) => {
    const response = await fetch(`${host}/club/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(content),
    });
    // console.log(content);
    const addedClub = await response.json();
    // setClubs(clubs.clubs.concat(addedClub));
    if(response.status===200 || response.status===201){
      return "success"
    }
    else {return "fail"}
  };

    //Adding Club
    const updateClubVisiblity = async (id) => {
      const response = await fetch(`${host}/club/updateClubVisibility/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log(content);
      // const addedClub = await response.json();
      // setClubs(clubs.clubs.concat(addedClub));
      if (response.status === 200) {
        setClubs({
          ...clubs,
          clubs: clubs.clubs.map((user) => {
            return {
              ...user,
              isVisible: user._id === id ? !user.isVisible : user.isVisible,
            };
          }),
        });
      }
      else {return "fail"}
    };

  const fetchClubsByArea = async(id)=>{
    const response = await fetch(`${host}/area/${id}`,{
      method: "GET",
      headers:{
        "Content-Type":"application/json"
      }
    })
    const json = await response.json();
    console.log(json)
    setClubsWithoutPage(json);
  }
  return (
    <ClubsContext.Provider
      value={{
        clubs,
        club,
        addClub,
        updateClub,
        fetchClubs,
        fetchClubsByVendor,
        fetchClub,
        fetchClubAdmin,
        setClub,
        clubsWithoutPage,
        fetchClubsByArea,
        totalPrice,
        setTotalPrice,
        optionInitialValue,
        orderDetails,
        setorderDetails,
        updateClubVisiblity
      }}
    >
      {props.children}
    </ClubsContext.Provider>
  );
};

export default ClubsState;
