import React, { useState } from "react";
import TicketsContext from "./ticketContext";
import { useNavigate, useLocation } from "react-router-dom";
import alertContext from "../alert/alertContext";
import { useContext } from "react";

const TicketsState = (props) => {
  const pathHook = useLocation();
  const path = pathHook.pathname.split("/");
  const navigate = useNavigate();

  const ContextAlert = useContext(alertContext);
  const {
    showModalAlert
  } = ContextAlert;
  const [tickets, setTickets] = useState({
    count: 0,
    tickets: [],
  });
  const fetchTickets = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/tickets/getallorder`,
      {
        method: "GET",

        headers: {
          "Content-Type": "application/json",
          // "auth-token": localStorage.getItem('token')
        },
      }
    );
    const json = await response.json();
    console.log(json);
    setTickets(json);
  };
  const fetchTicketsByVendor = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/tickets/getOrderByVendor`,
      {
        method: "GET",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("vendorToken")}`,
        },
      }
    );
    const json = await response.json();
    console.log(json);
    setTickets(json);
  };
  const fetchTicketsByUser = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/tickets/getbyusertoken`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    const json = await response.json();
    setTickets(json);
  };

  //   const addOrder = async (order) => {
  //     console.log(order);
  //     let q1 = order.tickets[0].quantity;
  //     let q2 = order.tickets[1].quantity;
  //     const newOrder = {
  //       // "User": order.User._id,
  //       clubId: order.clubId ? order.clubId._id : "",
  //       eventId: order.eventId ? order.eventId._id : "",
  //       orderDetails: {
  //         quantity: q1 > 0 ? q1 : q2 || q2 > 0 ? q2 : q1,
  //         // "amount": order.orderDetails.amount,
  //         // "payment_Status": order.orderDetails.payment_Status,
  //         // "refund_Status": order.orderDetails.refund_Status,
  //         // "cancel_Status": order.orderDetails.cancel_Status,
  //         // "order_time": order.orderDetails.order_time,
  //         // "cancellation_time": order.orderDetails.cancellation_time
  //       },
  //     };
  //     console.log(newOrder);

  //     const response = await fetch(`http://localhost:4000/tickets/post`, {
  //       method: "POST",
  // //to send amount
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(newOrder),
  //     });
  //     const ticket = await response.json();
  //     console.log(ticket._id);
  //     setTickets(tickets.tickets.concat(ticket));
  //   };

  const checkout = async (content, data) => {
    //  console.log(window)

    console.log(content);
    const eventId = content._id;
    let response;
    let obj = {
      order: {
        receipt: "",
        amount: 0,
        id: "",
      },
    };
    // if (data !== 0) {
    if (data >= 0) {
      response = await fetch(`${process.env.REACT_APP_HOST}/payments/new`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
        body: JSON.stringify({ amount: data }),
      });
      obj = await response.json();
    }
    console.log("order created");

    const unparsedUser = await fetch(
      `${process.env.REACT_APP_HOST}/user/token`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    const user = await unparsedUser.json();
    console.log("user: " + user);

    const newOrder = {
      User: user._id,
      datedUserId: content.datedUserId ? content.datedUserId : null,
      eventId: content._id,
      orderDetails: {
        vendorId: content.clubName.Vendor,
        option1: content.option1,
        option2: content.option2,
        option3: content.option3,
        option4: content.option4,
        option5: content.option5,
        refundTime: content.refundTime,
        isBid_Order: false,
        totalAmount: data,
        // payment_Status: data === 0 ? true : false,
        payment_Status: true,
        receipt: obj.order ? obj.order.receipt : obj.options.receipt,
      },
    };
    // const receipt = obj.order.receipt;
    const receipt = obj.order ? obj.order.receipt : obj.options.receipt;

    console.log(newOrder);
    const addOrder = await fetch(
      `${process.env.REACT_APP_HOST}/tickets/post/event/${eventId}`,
      {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
        body: JSON.stringify(newOrder),
      }
    );
    const json = await addOrder.json();
    console.log(json);
    if (json.success === false) {
      console.log("no tickets left");
      return;
    } else {
      console.log("order created");
    }

    console.log("going to make a payment");
    // if (data !== 0) {
    if (data < 0) {
      var options = {
        // eventId: content._id,
        key: "rzp_test_asemcJqO85eLRy", // Enter the Key ID generated from the Dashboard
        amount: obj.order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: content.name,
        description: content.description
          ? content.description.length > 250
            ? content.description.slice(0, 250)
            : content.description
          : "",

        image: content.coverImage
          ? content.coverImage.images[0].location
          : content.coverImg
          ? content.coverImg.images[0].location
          : "https://example.com/your_logo",
        // image: "https://example.com/your_logo",
        order_id: obj.order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        callback_url: `${process.env.REACT_APP_HOST}/payments/paymentverification/${receipt}`,
        // callback_url: `http://localhost:4000/payments/paymentverification/${eventId}`,
        prefill: {
          name: user.firstName + " " + user.lastName, //logged in user detail
          email: user.email,
          contact: user.contact,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#FF2252",
        },
      };

      var rzp = new window.Razorpay(options);
      rzp.open();
    } else {
      //we can implement an loader before navigating
      showModalAlert("Order Placed Successfully","success")
      navigate(`/${path[1]}/${eventId}`);
    }
  };
  const clubCheckout = async (content, orderDetails, data) => {
    const clubId = content._id;

    let response;
    let obj = {
      order: {
        receipt: "",
        amount: 0,
        id: "",
      },
    };

    // if (data !== 0) {
    if (data >= 0) {
      response = await fetch(`${process.env.REACT_APP_HOST}/payments/new`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
        body: JSON.stringify({ amount: data }),
      });
      obj = await response.json();
    }
    console.log("obj");
    console.log(obj);
    console.log("order created");

    const unparsedUser = await fetch(
      `${process.env.REACT_APP_HOST}/user/token`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    const user = await unparsedUser.json();
    console.log("user");
    console.log(user);
    console.log("content");
    console.log("content");
    console.log(content);
    const newOrder = {
      User: user._id,
      datedUserId: content.datedUserId ? content.datedUserId : null,
      clubId: content._id,
      orderDetails: {
        date: orderDetails.date,
        vendorId: content.Vendor,
        option1: orderDetails.option1,
        option2: orderDetails.option2,
        option3: orderDetails.option3,
        option4: orderDetails.option4,
        option5: orderDetails.option5,
        in_time: orderDetails.inTime,
        out_time: orderDetails.outTime,
        isBid_Order: false,
        refundTime: orderDetails.refundTime,
        totalAmount: data,
        // payment_Status: data === 0 ? true : false,
        payment_Status: true,
        dateId: orderDetails.dateId,
        receipt: obj.order ? obj.order.receipt : obj.options.receipt,
      },
    };
    const receipt = obj.order ? obj.order.receipt : obj.options.receipt;
    console.log("newOrder");
    console.log(newOrder);
    console.log("api called for create order");
    const addOrder = await fetch(
      `${process.env.REACT_APP_HOST}/tickets/post/club/${clubId}`,
      {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
        body: JSON.stringify(newOrder),
      }
    );
    console.log("add order api finished");
    const json = await addOrder.json();
    console.log(json);
    if (json.success === false) {
      console.log("no tickets left");
      return;
    } else {
      console.log("order created");
    }

    console.log("going to make a payment");
    // if (data !== 0) {
    if (data < 0) {
      var options = {
        // eventId: content._id,
        key: "rzp_test_asemcJqO85eLRy", // Enter the Key ID generated from the Dashboard
        amount: obj.order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: content.name,
        description:
          content.description.length > 250
            ? content.description.slice(0, 250)
            : content.description,
        image: content.coverImage
          ? content.coverImage.images[0].location
          : content.coverImg
          ? content.coverImg.images[0].location
          : "https://example.com/your_logo",
        // image: "https://example.com/your_logo",
        order_id: obj.order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        callback_url: `${process.env.REACT_APP_HOST}/payments/clubpaymentverification/${receipt}`,
        // callback_url: `http://localhost:4000/payments/paymentverification/${eventId}`,
        prefill: {
          name: user.firstName + " " + user.lastName, //logged in user detail
          email: user.email,
          contact: user.contact,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#FF2252",
        },
      };

      var rzp = new window.Razorpay(options);
      rzp.open();
    } else {
      showModalAlert("Order Placed Successfully","success")
      navigate(`/${path[1]}/${content._id}`);
    }
  };

  const bidCheckout = async (content, data) => {
    //  console.log(window)
    console.log("content");
    console.log(content);
    if (content.payment_Status === true) {
      return;
    }
    // let eventId;
    // if (content.eventId) {
    //   eventId = content.eventId._id;
    // }
    // let clubId;
    // if (content.clubId) {
    //   clubId = content.clubId._id;
    // }

    let response;
    let obj = {
      order: {
        receipt: "",
        amount: 0,
        id: "",
      },
    };
    if (data >= 0) {
      response = await fetch(`${process.env.REACT_APP_HOST}/payments/new`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
        body: JSON.stringify({ amount: data }),
      });
      obj = await response.json();
    }
    console.log("order created");

    const unparsedUser = await fetch(
      `${process.env.REACT_APP_HOST}/user/token`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );

    const user = await unparsedUser.json();
    console.log("user");
    console.log(user);
    let addOrder;
    let receipt;
    if (content.eventId) {
      let eventId = content.eventId._id;
      const newOrder = {
        User: user._id,
        eventId: content.eventId._id,
        orderDetails: {
          _id: content._id,
          vendorId: content.eventId.clubName.Vendor,
          option: content.option,
          isBid_Order: true,
          // receipt: obj.order.receipt,
          receipt: obj.order ? obj.order.receipt : obj.options.receipt,
          payment_Status: true,
          totalAmount: data,
        },
        quantity: content.quantity,
      };
      // receipt = obj.order.receipt;
      receipt = obj.order ? obj.order.receipt : obj.options.receipt;

      console.log(receipt);
      console.log(newOrder);
      addOrder = await fetch(
        `${process.env.REACT_APP_HOST}/tickets/post/bidevent/${eventId}`,
        {
          method: "POST",

          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
          body: JSON.stringify(newOrder),
        }
      );
    }
    // else {
    //   let clubId = content.clubId._id;
    //   const newOrder = {
    //     User: user._id,
    //     eventId: content.clubId._id,
    //     orderDetails: {
    //       option: content.option,
    //       isBid_Order: true,
    //       receipt: obj.order.receipt,
    //     },
    //   };
    //   receipt = obj.order.receipt;
    //   console.log(newOrder);
    //   addOrder = await fetch(
    //     `${process.env.REACT_APP_HOST}/tickets/post/club/${clubId}`,
    //     {
    //       method: "POST",

    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${localStorage.getItem("userToken")}`,
    //       },
    //       body: JSON.stringify(newOrder),
    //     }
    //   );
    // }

    const json = await addOrder.json();
    console.log(json);
    if (json.success === false) {
      console.log("no tickets left");
      return;
    } else {
      console.log("order created");
    }

    console.log("going to make a payment");
    if (data < 0) {
      var options = {
        // eventId: content._id,
        key: "rzp_test_asemcJqO85eLRy", // Enter the Key ID generated from the Dashboard
        amount: obj.order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: content.name,
        description: content.description
          ? content.description.length > 250
            ? content.description.slice(0, 250)
            : content.description
          : "",

        image: content.coverImage
          ? content.coverImage.images[0].location
          : content.coverImg
          ? content.coverImg.images[0].location
          : "https://example.com/your_logo",
        // image: "https://example.com/your_logo",
        order_id: obj.order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        callback_url: `${process.env.REACT_APP_HOST}/payments/bidpaymentverification/${receipt}`,
        // callback_url: `http://localhost:4000/payments/paymentverification/${eventId}`,
        prefill: {
          name: user.firstName + " " + user.lastName, //logged in user detail
          email: user.email,
          contact: user.contact,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#FF2252",
        },
      };
      console.log(options);
      var rzp = new window.Razorpay(options);
      rzp.open();
    } else {
      //we can implement an loader before navigating
      showModalAlert("Order Placed Successfully","success")
      navigate(`/${path[1]}/${content.eventId._id}`);
    }
  };

  const clubBidCheckout = async (content, data) => {
    //  console.log(window)
    //  let orderDetails = 0;
    console.log("content");
    console.log("content");
    console.log("content");
    console.log(content);
    if (content.payment_Status === true) {
      return;
    }
    console.log("orderDetails");
    console.log("orderDetails");
    // console.log(orderDetails);
    const clubId = content.clubId._id;

    let response;
    let obj = {
      order: {
        receipt: "",
        amount: 0,
        id: "",
      },
    };

    if (data >= 0) {
      response = await fetch(`${process.env.REACT_APP_HOST}/payments/new`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
        body: JSON.stringify({ amount: data }),
      });
      obj = await response.json();
    }
    console.log("order created");

    const unparsedUser = await fetch(
      `${process.env.REACT_APP_HOST}/user/token`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    console.log("obj");
    console.log(obj);
    const user = await unparsedUser.json();
    console.log("user");
    console.log(user);
    const newOrder = {
      User: user._id,
      clubId: content.clubId._id,
      orderDetails: {
        _id: content._id,
        option: content.option,
        vendorId: content.clubId.Vendor,
        isBid_Order: true,
        // receipt: obj.order.receipt,
        receipt: obj.order ? obj.order.receipt : obj.options.receipt,
        payment_Status: true,
      },
      quantity: content.quantity,
      totalAmount: data,
      dateId: content.dateId,
    };
    // const receipt = obj.order.receipt;
    const receipt = obj.order ? obj.order.receipt : obj.options.receipt;

    console.log("newOrder");
    console.log(newOrder);
    const addOrder = await fetch(
      `${process.env.REACT_APP_HOST}/tickets/post/bidclub/${clubId}`,
      {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
        body: JSON.stringify(newOrder),
      }
    );
    const json = await addOrder.json();
    console.log(json);
    if (json.success === false) {
      console.log("no tickets left");
      return;
    } else {
      console.log("order created");
    }

    console.log("going to make a payment");
    if (data < 0) {
      var options = {
        // eventId: content._id,
        key: "rzp_test_asemcJqO85eLRy", // Enter the Key ID generated from the Dashboard
        amount: obj.order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: content.clubId.name,
        description: content.clubId.description
          ? content.clubId.description.length > 250
            ? content.clubId.description.slice(0, 250)
            : content.clubId.description
          : "",
        image: "https://example.com/your_logo",
        // image: "https://example.com/your_logo",
        order_id: obj.order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        callback_url: `${process.env.REACT_APP_HOST}/payments/bidclubpaymentverification/${receipt}`,
        // callback_url: `http://localhost:4000/payments/paymentverification/${eventId}`,
        prefill: {
          name: user.firstName + " " + user.lastName, //logged in user detail
          email: user.email,
          contact: user.contact,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#FF2252",
        },
      };
      console.log(options);
      var rzp = await new window.Razorpay(options);
      rzp.open();
    } else {
      showModalAlert("Order Placed Successfully","success")
      navigate(`/${path[1]}/${content._id}`);
    }
  };

  const partyPlannerCheckout = async (content, data) => {
    //  console.log(window)
    console.log(content);
    const clubId = content.selectedByUser.value._id;
    let response;
    let obj = {
      order: {
        receipt: "",
        amount: 0,
        id: "",
      },
    };
    if (data >= 0) {
      response = await fetch(`${process.env.REACT_APP_HOST}/payments/new`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
        body: JSON.stringify({ amount: data }),
      });
      obj = await response.json();
    }
    console.log("order created");

    const unparsedUser = await fetch(
      `${process.env.REACT_APP_HOST}/user/token`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    const user = await unparsedUser.json();
    console.log("user");
    console.log(user);
    console.log("content");
    console.log("content");
    console.log(content);
    const newOrder = {
      User: user._id,
      clubId: content.selectedByUser.value._id,
      orderDetails: {
        vendorId: content.selectedByUser.value.Vendor,
        // receipt: obj.order.receipt,
        receipt: obj.order ? obj.order.receipt : obj.options.receipt,
        totalAmount: data,
        _id: content._id,
        isparty_planned: true,
      },
    };
    // const receipt = obj.order.receipt;
    const receipt = obj.order ? obj.order.receipt : obj.options.receipt;

    console.log("newOrder");
    console.log(newOrder);
    const addOrder = await fetch(
      `${process.env.REACT_APP_HOST}/tickets/post/partyplanner/${clubId}`,
      {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
        body: JSON.stringify(newOrder),
      }
    );
    const json = await addOrder.json();
    console.log(json);
    if (json.success === false) {
      console.log("no tickets left");
      return;
    } else {
      console.log("order created");
    }

    console.log("going to make a payment");
    if (data < 0) {
      var options = {
        // eventId: content._id,
        key: "rzp_test_asemcJqO85eLRy", // Enter the Key ID generated from the Dashboard
        amount: obj.order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: content.name,
        description: content.description
          ? content.description.length > 250
            ? content.description.slice(0, 250)
            : content.description
          : "",
        image: content.coverImage
          ? content.coverImage.images[0].location
          : content.coverImg
          ? content.coverImg.images[0].location
          : "https://example.com/your_logo",
        // image: "https://example.com/your_logo",
        order_id: obj.order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        callback_url: `${process.env.REACT_APP_HOST}/payments/partyplannerpaymentverification/${receipt}`,
        // callback_url: `http://localhost:4000/payments/paymentverification/${eventId}`,
        prefill: {
          name: user.firstName + " " + user.lastName, //logged in user detail
          email: user.email,
          contact: user.contact,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#FF2252",
        },
      };

      var rzp = new window.Razorpay(options);
      rzp.open();
    } else {
      showModalAlert("Order Placed Successfully","success")
      navigate(`/`);
    }
  };

  const dateRequestCheckout = async (content, data) => {
    //  console.log(window)
    // let orderDetails = 0;
    if (content.orderDetails.date_payment_Status === true) {
      return;
    }
    console.log("content");
    console.log("content");
    console.log("content");
    console.log(content);
    console.log("total");
    console.log("total");
    console.log(data);
    console.log("orderDetails");
    console.log("orderDetails");
    // console.log(orderDetails);
    const clubId = content.clubId._id;
    let response;
    let obj = {
      order: {
        receipt: "",
        amount: 0,
        id: "",
      },
    };
    if (data >= 0) {
      response = await fetch(`${process.env.REACT_APP_HOST}/payments/new`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
        body: JSON.stringify({ amount: data }),
      });
      obj = await response.json();
    }
    console.log("order created");

    const unparsedUser = await fetch(
      `${process.env.REACT_APP_HOST}/user/token`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    console.log("obj");
    console.log(obj);
    const user = await unparsedUser.json();
    console.log("user");
    console.log(user);
    const newOrder = {
      orderDetails: {
        vendorId: content.clubId.Vendor,
        option1: content.orderDetails.option1,
        option2: content.orderDetails.option2,
        option3: content.orderDetails.option3,
        option4: content.orderDetails.option4,
        option5: content.orderDetails.option5,
        isBid_Order: false,
        dateId: content.orderDetails.dateId,
        // receipt: obj.order.receipt,
        receipt: obj.order ? obj.order.receipt : obj.options.receipt,

        totalAmount: data,
        payment_Status: true,
        date_payment_Status:true
      },
    };
    // const receipt = obj.order.receipt;
    const receipt = obj.order ? obj.order.receipt : obj.options.receipt;

    console.log("newOrder");
    console.log(newOrder);
    const addOrder = await fetch(
      `${process.env.REACT_APP_HOST}/tickets/updatedaterequest/${content.orderId}`,
      {
        method: "PUT",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
        body: JSON.stringify(newOrder),
      }
    );
    const json = await addOrder.json();
    console.log(json);
    if (json.success === false) {
      console.log("no tickets left");
      return;
    } else {
      console.log("order created");
    }

    console.log("going to make a payment");
    if (data < 0) {
      var options = {
        // eventId: content._id,
        key: "rzp_test_asemcJqO85eLRy", // Enter the Key ID generated from the Dashboard
        amount: obj.order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: content.clubId.name,
        description: content.clubId.description
          ? content.clubId.description.length > 250
            ? content.clubId.description.slice(0, 250)
            : content.clubId.description
          : "",
        image: "https://example.com/your_logo",
        // image: "https://example.com/your_logo",
        order_id: obj.order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        callback_url: `${process.env.REACT_APP_HOST}/payments/daterequestclubpaymentverification/${receipt}`,
        // callback_url: `http://localhost:4000/payments/paymentverification/${eventId}`,
        prefill: {
          name: user.firstName + " " + user.lastName, //logged in user detail
          email: user.email,
          contact: user.contact,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#FF2252",
        },
      };
      console.log(options);
      var rzp = await new window.Razorpay(options);
      rzp.open();
    } else {
      showModalAlert("Order Placed Successfully","success")
      navigate(`/`);
    }
  };

  const dateRequestCheckoutEvent = async (content, data) => {
    //  console.log(window)
    // let orderDetails = 0;
    if (content.orderDetails.date_payment_Status === true) {
      return;
    }
    console.log("content");
    console.log("content");
    console.log("content");
    console.log(content);
    console.log("total");
    console.log("total");
    console.log(data);
    console.log("orderDetails");
    console.log("orderDetails");
    // console.log(orderDetails);
    const clubId = content.clubId._id;

    let response;
    let obj = {
      order: {
        receipt: "",
        amount: 0,
        id: "",
      },
    };
    if (data >= 0) {
      response = await fetch(`${process.env.REACT_APP_HOST}/payments/new`, {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
        body: JSON.stringify({ amount: data }),
      });
      obj = await response.json();
    }
    console.log("order created");

    const unparsedUser = await fetch(
      `${process.env.REACT_APP_HOST}/user/token`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    console.log("obj");
    console.log(obj);
    const user = await unparsedUser.json();
    console.log("user");
    console.log(user);
    const newOrder = {
      eventId: content.eventId._id,
      orderDetails: {
        vendorId: content.eventId.clubName.Vendor,
        option1: content.orderDetails.option1,
        option2: content.orderDetails.option2,
        option3: content.orderDetails.option3,
        option4: content.orderDetails.option4,
        option5: content.orderDetails.option5,
        isBid_Order: false,
        dateId: content.orderDetails.dateId,
        // receipt: obj.order.receipt,
        receipt: obj.order ? obj.order.receipt : obj.options.receipt,
        payment_Status: true,
        date_payment_Status:true,
        totalAmount: data,
      }
    };
    // const receipt = obj.order.receipt;
    const receipt = obj.order ? obj.order.receipt : obj.options.receipt;

    console.log("newOrder");
    console.log(newOrder);
    const addOrder = await fetch(
      `${process.env.REACT_APP_HOST}/tickets/updatedaterequestevent/${content.orderId}`,
      {
        method: "PUT",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
        body: JSON.stringify(newOrder),
      }
    );
    const json = await addOrder.json();
    console.log(json);
    if (json.success === false) {
      console.log("no tickets left");
      return;
    } else {
      console.log("order created");
    }

    console.log("going to make a payment");
    if (data < 0) {
      var options = {
        // eventId: content._id,
        key: "rzp_test_asemcJqO85eLRy", // Enter the Key ID generated from the Dashboard
        amount: obj.order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: content.eventId.name,
        description: content.eventId.description
          ? content.eventId.description.length > 250
            ? content.eventId.description.slice(0, 250)
            : content.eventId.description
          : "",
        image: "https://example.com/your_logo",
        // image: "https://example.com/your_logo",
        order_id: obj.order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        callback_url: `${process.env.REACT_APP_HOST}/payments/daterequesteventpaymentverification/${receipt}`,
        // callback_url: `http://localhost:4000/payments/paymentverification/${eventId}`,
        prefill: {
          name: user.firstName + " " + user.lastName, //logged in user detail
          email: user.email,
          contact: user.contact,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#FF2252",
        },
      };
      console.log(options);
      var rzp = await new window.Razorpay(options);
      rzp.open();
    } else {
      showModalAlert("Order Placed Successfully","success")
      navigate(`/`);
    }
  };

  const fetchSettlements = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/tickets/getallorderofvendors`,
      {
        method: "GET",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
        },
      }
    );
    const json = await response.json();
    console.log(json);
    setTickets(json);
  };

  return (
    <TicketsContext.Provider
      value={{
        tickets,
        fetchTickets,
        fetchTicketsByUser,
        checkout,
        clubCheckout,
        bidCheckout,
        clubBidCheckout,
        dateRequestCheckout,
        dateRequestCheckoutEvent,
        partyPlannerCheckout,
        fetchTicketsByVendor,
        fetchSettlements,
      }}
    >
      {props.children}
    </TicketsContext.Provider>
  );
};

export default TicketsState;
