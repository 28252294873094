import React, { useContext, useState } from "react";
import BidsContext from "./bidContext";
import AuthContext from "../auth/authContext";

const bids_DUMMY_DATA = {
  count: 1,
  bids: [
    {
      _id: 1234,
      User: {
        _id: 1,
        firstName: "Vaibhav",
        email: "vaibhavgupta123@gmail.com",
      },
      clubId: { _id: 1234, name: "G Club" },
      eventId: { _id: 123, name: "DJ Rhea concert", clubName: "G lub" },
      date: "22 Nov",
      quantity: "10",
      bid_price: "500",
      approval: 1,
    },
  ],
};

const BidsState = (props) => {
  const [bids, setBids] = useState({
    count: 0,
    bids: [],
  });
  const [bid, setBid] = useState(null);
  const ContextAuth=useContext(AuthContext)
  const {token}=ContextAuth
  const fetchBidsVendor = async () => {
    const response = await fetch(`${process.env.REACT_APP_HOST}/bid/vendor`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("vendorToken")}`,
      },
    });
    if (response.status === 200 || response.status === 201) {
      const json = await response.json();
      setBids(json);
    }
  };
  const fetchBidsAdmin = async () => {
    const response = await fetch(`${process.env.REACT_APP_HOST}/bid/admin`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200 || response.status === 201) {
      const json = await response.json();
      setBids(json);
    }
  };
  const fetchBidsUser = async () => {
    const response = await fetch(`${process.env.REACT_APP_HOST}/bid/user`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });

    const json = await response.json();
    console.log(json);
    setBids(json);
  };

  const fetchBidById = async (id, token) => {
    const response = await fetch(`${process.env.REACT_APP_HOST}/bid/id/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const json = await response.json();
    console.log(json);
    setBids(json);
  };
  return (
    <BidsContext.Provider
      value={{
        bid,
        setBid,
        setBids,
        bids,
        fetchBidsVendor,
        fetchBidsAdmin,
        fetchBidsUser,
        fetchBidById,
      }}
    >
      {props.children}
    </BidsContext.Provider>
  );
};

export default BidsState;
