import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import UsersContext from "./userContext";


const UsersState = (props) => {
  const user_initial_DUMMY_DATA = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    contact: "",
    // registerDate: "",
    userImg: null,
    blockStatus: false,
    code: null,
  };
  const [users, setUsers] = useState({
    count: 0,
    users: [],
  });

  const fetchUsers = async () => {
    // setUsers(users_DUMMY_DATA);
    const response = await fetch(`${process.env.REACT_APP_HOST}/user`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
      },
    });
    const json = await response.json();
    setUsers(json);
  };
  const updateBlockStatus = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/user/status/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${
            localStorage.getItem("adminToken")
              ? localStorage.getItem("adminToken")
              : localStorage.getItem("vendorToken")
          }`,
        },
        // body: JSON.stringify(content),
      }
    );
    if (response.status === 200) {
      setUsers({
        ...users,
        users: users.users.map((user) => {
          return {
            ...user,
            blockStatus: user._id === id ? !user.blockStatus : user.blockStatus,
          };
        }),
      });
      // setUsers({
      //   ...users,
      //   users: users.users.filter((user) => {
      //     return user._id!==id
      //   }),
      // });
    }
  };
  const [user, setUser] = useState(user_initial_DUMMY_DATA);
  const fetchUser = async () => {
    // setUser(user_DUMMY_DATA);
    const response = await fetch(`${process.env.REACT_APP_HOST}/user/token`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
    });
    const json = await response.json();
    setUser(json);
  };
  return (
    <UsersContext.Provider
      value={{
        users,
        fetchUsers,
        user,
        fetchUser,
        setUser,
        updateBlockStatus,
        user_initial_DUMMY_DATA
      }}
    >
      {props.children}
    </UsersContext.Provider>
  );
};

export default UsersState;
