import React from 'react'
import Spinner from '../spinner/spinner1'

const Loading = () => {
  return (
    <div className='w-full d-flex justify-content-center align-items-center' style={{
      height:"100vh"
    }}><Spinner /></div>
  )
}

export default Loading