import React, { useContext, useState } from "react";
import PartyPlannerContext from "./partyPlannerContext";
import AuthContext from "../auth/authContext";

const partyPlannerRequests_DUMMY_DATA = {
  count: 1,
  requests: [
    {
      _id: 1234,
      User: {
        _id: "1234",
        email:"vaibhavgupta123@gmail.com"
      },
      city: "",
      area_name: "",
      pincode: "",
      clubName: { name: "G Club" },
      party_date: "20 Nov 2022",
      approval_date: "20 Nov 2022",
      in_time: "18:00",
      out_time: "20:00",
      budget_per: 500,
      total_person: 20,
      createdAt: "29 Oct 2022",
      status: 1,
    },
  ],
};
const partyPlannerRequest_DUMMY_DATA = {
  _id: 1234,
  city: "",
  area_name: "",
  pincode: "",
  clubName: "",
  party_date: "",
  in_time: "",
  out_time: "",
  budget_per: "",
  total_person: "",
};

const PartyPlannerState = (props) => {
  const ContextAuth = useContext(AuthContext);
  const { token } = ContextAuth;
  const [partyRequests, setPartyRequests] = useState({
    count: 0,
    requests: [],
  });
  const [partyRequest, setPartyRequest] = useState({
    city: "",
    area_name: "",
    pincode: "",
    clubName: "",
    party_date: "",
    in_time: "",
    out_time: "",
    budget_per: "",
    total_person: "",
  });

  const fetchPartyRequestsUser = async() => {
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/partyplanner/user`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      })

    const json = await response.json();
    console.log("json");
    console.log("json");
    console.log(json);
    setPartyRequests(json);
  };
  const fetchPartyRequestsAdmin = async() => {
    console.log(token)
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/partyplanner/admin`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })

    const json = await response.json();
    console.log(json);
    setPartyRequests(json);
  };
  const fetchPartyRequest = (id) => {
    setPartyRequest(partyPlannerRequest_DUMMY_DATA);
  };


  const [partyData, setPartyData] = useState({
    city: "",
    area_name: "",
    pincode: "",
    clubName: "",
    party_date: "",
    in_time: "",
    out_time: "",
    budget_per: "",
    total_person: "",
    selectedByUser:{}
  });

  const fetchPartyById = async (id) => {
    console.log("hello")
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/partyplanner/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );

    const json = await response.json();
    console.log(json);
    setPartyData(json);
  };

  const [clubData, setClubData] = useState({
    areaName: "",
    pinCode: "",
    cityName: "",
    coverImage: "",
  });

  const club = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/club/details/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const json = await response.json();
    console.log("json");
    console.log("json")
    console.log(json);
    setClubData(json);
  };

  return (
    <PartyPlannerContext.Provider
      value={{
        partyRequest,
        setPartyRequest,
        setPartyRequests,
        fetchPartyRequestsUser,
        fetchPartyRequestsAdmin,
        partyRequests,
        fetchPartyRequest,
        partyData,
        setPartyData,
        fetchPartyById,
        club, 
        clubData,
        setClubData
      }}
    >
      {props.children}
    </PartyPlannerContext.Provider>
  );
};

export default PartyPlannerState;
