import React, { useContext, useState } from "react";
import ThemeContext from "./themeContext";

const ThemeState = (props) => {
  const [darkMode, setDarkMode] = useState(false);
  const enableDarkMode=()=>{
    setDarkMode(true)
    return null
  }
  const disableDarkMode=()=>{
    setDarkMode(false)
    return null
  }
  return (
    <ThemeContext.Provider
      value={{
        darkMode,
        enableDarkMode,
        disableDarkMode
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeState;
