import React, { useState } from "react";
import VendorsContext from "./vendorsContext";
import { useLocation } from "react-router-dom";

const host = process.env.REACT_APP_HOST;

const VendorsState = (props) => {
  const [vendors, setVendors] = useState([]);
  const pathHook = useLocation();
  const path = pathHook.pathname.split("/");
  const fetchVendors = async () => {
    try {
      const response = await fetch(`${host}/vendor`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${path[1]==='teleuser'?localStorage.getItem("teleToken"):localStorage.getItem("adminToken")}`,
        },
      });
      const vendorParsed = await response.json();
      setVendors(vendorParsed);
    } catch (err) {
      console.log(err);
    }
  };

  //send OTP
  const fetchOtp = async (email) => {
    const response = await fetch(`${host}/register/otp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "auth-token": localStorage.getItem("token"),
      },
      body: JSON.stringify({ email: email }),
    });
    const vendor = await response.json();
  };

  const updateBlockStatus = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/vendor/status/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
        },
        // body: JSON.stringify(content),
      }
    );
    await response.json();
  };
  return (
    <VendorsContext.Provider
      value={{
        setVendors,
        vendors,
        fetchVendors,
        fetchOtp,
        updateBlockStatus,
      }}
    >
      {props.children}
    </VendorsContext.Provider>
  );
};

export default VendorsState;
