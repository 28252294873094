import React, {useState} from "react";
import FooterContext from "./footerContext";

const FooterState = (props) => {
    const control ={
        contactno:"+91123456789",
        email:"email@gmail.com",
        address:"abc,Jaipur Raj,302004"
    }

    const [companyInfo, setCompanyInfo] = useState([])

    const fetchCompanyInfo = async () => {
      
      const response = await fetch(`${process.env.REACT_APP_HOST}/control/get`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const json = await response.json();
      console.log(json);
      // return json;
      setCompanyInfo(json);
    };
    return (
        <FooterContext.Provider
          value={{
            companyInfo,
            fetchCompanyInfo
          }}
        >
          {props.children}
        </FooterContext.Provider>
      );
    };
    
    export default FooterState;