import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react';
import { useLocation ,useNavigate} from 'react-router-dom';

import './scrollButton.css'
const ScrollButton = () => {
    const [visible, setVisible] = useState(false)
  
    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300){
        setVisible(true)
      } 
      else if (scrolled <= 300){
        setVisible(false)
      }
    };
    
    const scrollToTop = () =>{
      window.scrollTo({
        top: 0, 
        behavior: 'smooth'
        /* you can also use 'auto' behaviour
           in place of 'smooth' */
      });
    };
    
    window.addEventListener('scroll', toggleVisible);
  return (
    visible&&<button onClick={scrollToTop} className='ScrollUpButton'><i class="fa-solid fa-arrow-up"></i></button>
  )
}


export const ScrollToTopInstant=()=>{
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the navigation is forward or back
    const isBackNavigation = navigate?.location?.state?.back;

    if (!isBackNavigation) {
      window.scrollTo({
        top: 0, 
        behavior: 'instant'
        /* you can also use 'auto' behaviour
           in place of 'smooth' */
      });
    }
  }, [pathname, navigate]);

  return null;
}


export default ScrollButton