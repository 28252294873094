import React, { useState } from "react";
import AuthContext from "./authContext";
import userContext from "../users/userContext";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
const bids_DUMMY_DATA = {
  count: 1,
  bids: [
    {
      _id: 1234,
      User: {
        _id: 1,
        firstName: "Vaibhav",
        email: "vaibhavgupta123@gmail.com",
      },
      clubId: { _id: 1234, name: "G Club" },
      eventId: { _id: 123, name: "DJ Rhea concert", clubName: "G lub" },
      date: "22 Nov",
      quantity: "10",
      bid_price: "500",
      approval: 1,
    },
  ],
};

const AuthState = (props) => {
  // const contextUser = React.useContext(userContext);
  // const { user,setUser } = contextUser;
  const [user, setUser] = useState(null);
  const [bids, setBids] = useState({
    count: 0,
    bids: [],
  });
  const [isLogin, setIsLogin] = useState(false);
  const [isDating, setIsDating] = useState(false);
  const fetchBids = () => {
    setBids(bids_DUMMY_DATA);
  };

  const pathHook = useLocation();
  const path = pathHook.pathname.split("/");
  const role = path[1];
  const [token, setToken] = useState(null);
  let staticToken;
  const [isVendor, setIsVendor] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isTele, setIsTele] = useState(false);
  const [isUser, setIsUser] = useState(false);
  useEffect(() => {
    console.log(path[1])
    console.log(localStorage.getItem("adminToken"));
    
  }, [path[1]]);

  const fetchUser = async (token) => {
    // setUser(user_DUMMY_DATA);
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/user/by/token`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  };
  const settingUser = async () => {
    console.log("setting user");
    if (path[1] === "admin") {
      setToken(localStorage.getItem("adminToken"));
      staticToken=localStorage.getItem("adminToken")
      setIsAdmin(true);
      setIsVendor(false);
      setIsTele(false);
      setIsUser(false);
    } else if (path[1] === "vendor") {
      setToken(localStorage.getItem("vendorToken"));
      staticToken=localStorage.getItem("vendorToken")
      setIsVendor(true);
      setIsAdmin(false);
      setIsTele(false);
      setIsUser(false);
    } else if (path[1] === "teleuser") {
      setToken(localStorage.getItem("teleToken"));
      staticToken=localStorage.getItem("teleToken")
      setIsTele(true);
      setIsAdmin(false);
      setIsVendor(false);
      setIsUser(false);
    } else {
      setToken(localStorage.getItem("userToken"));
      staticToken=localStorage.getItem("userToken")
      setIsUser(true);
      setIsAdmin(false);
      setIsVendor(false);
      setIsTele(false);
    }

    const response = await fetchUser(staticToken);
    if (!response.ok) {
      const parsedResponse = await response.text();
      if (parsedResponse === "jwt expired") {
        localStorage.removeItem("userToken");
        localStorage.removeItem("adminToken");
        localStorage.removeItem("vendorToken");
        localStorage.removeItem("teleToken");
        setIsLogin(false);
      }
    } else if (response.ok) {
      const jsonResponse = await response.json();
      setUser(jsonResponse);
      if (jsonResponse.dating) setIsDating(true);
      else setIsDating(false);
      setIsLogin(true);
    } else {
      console.log(response);
    }
  };

  const [loginModal, setLoginModal] = React.useState(false);

  return (
    <AuthContext.Provider
      value={{
        bids,
        fetchBids,
        isLogin,
        loginModal,
        setLoginModal,
        setIsLogin,
        fetchUser,
        settingUser,
        isDating,
        user,
        token,
        setToken,
        isUser,
        isAdmin,
        isTele,
        isVendor,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
