import React, { useState } from "react";
import AlertContext from "./alertContext";

const AlertState = (props) => {
  const [alert, setAlert] = useState(false);
  const [severity, setSeverity] = useState(null);
  const [message, setMessage] = useState(null);
  const [modalSeverity, setModalSeverity] = useState(null);
  const [modalAlert, setModalAlert] = useState(false);
  const [modalMessage, setModalMessage] = useState(null);

  const showAlert=(message,severity)=>{
    setMessage(message)
    setSeverity(severity)
    setAlert(true)
  }
  const showModalAlert=(message,severity)=>{
    setModalMessage(message)
    setModalSeverity(severity)
    setModalAlert(true)
  }
  const hideModalAlert=()=>{
    setModalMessage("")
    setModalSeverity("")
    setModalAlert(false)
  }
  return (
    <AlertContext.Provider
      value={{
        alert,severity,message,showAlert,setAlert,showModalAlert,hideModalAlert,modalSeverity,modalAlert,modalMessage
      }}
    >
      {props.children}
    </AlertContext.Provider>
  );
};

export default AlertState;
