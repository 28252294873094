import React,{useState} from "react";
import AfterPartyContext from "./afterPartyContext";

const afterPartyPhotos_DUMMY_DATA={
  count:1,
  afterPartyPhotos:[
      {
      id:1,
      clubName:{
        _id:"1234",
        name:"Blackout"
      },
      category:{
        _id:"1",
        categoryName:"Happy Hours"
      },
      title:"Girls Chilling",
      image:"https://media.istockphoto.com/photos/happy-young-women-enjoying-cocktail-in-night-club-party-picture-id518090350?k=20&m=518090350&s=612x612&w=0&h=X5Koj_vAaeHeaMyEgB5ZUxVF7gw4tdn4vWRNm0myOf0=",
      imageDes: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Blanditiis porro rerum perferendis, vel doloremque deleniti assumenda nesciunt dolorum voluptatibus quisquam et sapiente laudantium sit neque rem molestiae ullam nobis. Qui!"
  }
]
}
const AfterPartyState = (props) => {
    const [afterPartyPhotos, setAfterPartyPhotos] = useState({
      count:0,
      afterPartyPhotos:[]
    })
    const fetchAfterPartyPhotos=()=>{
      setAfterPartyPhotos(afterPartyPhotos_DUMMY_DATA)
    }
    return (
        <AfterPartyContext.Provider
          value={{
            afterPartyPhotos,
            fetchAfterPartyPhotos
          }}
        >
          {props.children}
        </AfterPartyContext.Provider>
      );
    };
    
    export default AfterPartyState;