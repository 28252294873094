import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ClubsState from "./context/clubs/ClubsState";
import FooterState from "./context/footer/FooterState";
import CategoriesState from "./context/categories/CategoriesState";
import EventsState from "./context/events/EventState";
import LocationState from "./context/location/LocationState";
import AfterPartyState from "./context/afterParty/afterPartyState";
import TicketsState from "./context/ticket/TicketsState";
import AuthState from "./context/auth/AuthState";
import UsersState from "./context/users/UsersState";
import VendorsState from "./context/vendor/VendorsState";
import ReviewsState from "./context/comments/ReviewsState";
import PartyPlannerState from "./context/partyPlanner/partyPlannerState";
import BidsState from "./context/bids/BidsState";
import BlogState from "./context/blog/blogState";
import ImageUploadState from "./context/Image/imageUploadState";
import AlertState from "./context/alert/AlertsState";
import ThemeState from "./context/theme/ThemeState";
import DatingState from "./context/dating/DatingState";
import { useState } from "react";
import { useEffect } from "react";
import ScrollButton, { ScrollToTopInstant } from "./shared/scrollButton/scrollButton";
import RootRoutes from "./Routes";
function App() {
  const [Loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);
  }, []);
  if (Loading) {
    return null;
  }

  return (
    <div className="App">
      <Router>
        {/* <Snow/> */}
    <AuthState>
        <UsersState>
          <ThemeState>
                                        <AlertState>
            <FooterState>
              <ClubsState>
                <CategoriesState>
                  <EventsState>
                    <LocationState>
                      <AfterPartyState>
                        <TicketsState>
                          <VendorsState>
                            <ReviewsState>
                              <PartyPlannerState>
                                <BidsState>
                                  <BlogState>
                                      <ImageUploadState>
                                          <DatingState>
                                            <ScrollButton />
                                            <ScrollToTopInstant />
                                            <RootRoutes />
                                          </DatingState>
                                      </ImageUploadState>
                                  </BlogState>
                                </BidsState>
                              </PartyPlannerState>
                            </ReviewsState>
                          </VendorsState>
                        </TicketsState>
                      </AfterPartyState>
                    </LocationState>
                  </EventsState>
                </CategoriesState>
              </ClubsState>
            </FooterState>
                                        </AlertState>
          </ThemeState>
        </UsersState>
                                    </AuthState>
      </Router>
    </div>
  );
}

export default App;

//Hi Divyanshu !
