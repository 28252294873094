import React, { useState } from "react";
import ReviewsContext from "./reviewContext";

const reviews_DUMMY_DATA = {
  count: 1,
  reviews: [
    {
      _id: 1234,
      User: {
        _id: "1",
        firstName: "Vaibhav",
        lastName: "Gupta",
        avtar:
          "https://image.shutterstock.com/image-vector/abstract-girl-avtar-character-fiction-260nw-2168820235.jpg",
      },
      ratings: 3,
      description: "Good",
    },
  ],
};

const ReviewsState = (props) => {
  const [reviews, setReviews] = useState({
    count: 0,
    reviews: [],
  });
  const fetchReviews = async (id) => {
    // setReviews(reviews_DUMMY_DATA);
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/comment/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      }
    );
    const json = await response.json();
    console.log(json);
    // return json;
    setReviews(json);
  };

  const postReviews = async(content)=>{
    const response = await fetch(`${process.env.REACT_APP_HOST}/comment`, {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("userToken")}`,
      },
      body: JSON.stringify(content),
    });
     await response.json();
    //  if(response.status === 200){
    //   (async()=>{
    //     const clubCopy = await fetchClub(id);
    //     setClub(clubCopy)
    //     console.log(club);
    //   })()
    //   fetchReviews(id);
    //  }
  }
  return (
    <ReviewsContext.Provider
      value={{
        reviews,
        fetchReviews,
        postReviews
      }}
    >
      {props.children}
    </ReviewsContext.Provider>
  );
};

export default ReviewsState;
