import React, { Suspense } from "react";
import {
  Routes,
  Route,
  useLocation,
  redirect,
  Navigate,
} from "react-router-dom";
import alertContext from "./context/alert/alertContext";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useContext } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Loading from "./shared/Loading/Loading";
import { useEffect } from "react";
import AuthContext from "./context/auth/authContext";
import themeContext from "./context/theme/themeContext";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const User = React.lazy(() => import("./user/user"));
const Admin = React.lazy(() => import("./admin/admin"));
const AdminLogin = React.lazy(() => import("./admin/pages/auth/adminLogin"));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "820px",
  maxWidth: "90vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight:'95vh',
  overflowY:'scroll'
};

const RootRoutes = () => {
  const pathHook = useLocation();
  const path = pathHook.pathname.split("/");
  const ContextAlert = useContext(alertContext);
  const contextTheme = useContext(themeContext);
  const { darkMode  } = contextTheme;
  const {
    alert,
    setAlert,
    severity,
    message,
    modalAlert,
    modalMessage,
    hideModalAlert,
  } = ContextAlert;
  const ContextAuth = useContext(AuthContext);
  const { settingUser } = ContextAuth;

  useEffect(() => {
    if (
      localStorage.getItem("adminToken") ||
      localStorage.getItem("vendorToken") ||
      localStorage.getItem("teleToken") ||
      localStorage.getItem("userToken")
    )
      (async () => {
        await settingUser();
      })();
  }, []);

  return (
    <>
      <Snackbar
        open={alert}
        autoHideDuration={3000}
        onClose={() => {
          setAlert(false);
        }}
      >
        <Alert
          onClose={() => {
            setAlert(false);
          }}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalAlert}
        onClose={hideModalAlert}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="partyPlannerModal"
      >
        <Box sx={style} className={`${darkMode && "dark"}`}>
          <div className="modal-heading">
            <h1>
              {/* Plan Your <span className="highlighted">Party</span> */}
            </h1>
            <i
              className="fa-solid fa-xmark "
              role="button"
              onClick={hideModalAlert}
            ></i>
          </div>
          <div className="modal-heading">
          <h1>
              Order Placed <span className="highlighted">Successfully</span>
          </h1>

          {/* <button type="button" className="btn btn-outline-primary" onClick={()=>{navigate('/ticket/'+modalOrderId)}}>View Ticket</button> */}
          </div>
        </Box>
      </Modal>

      <Routes>
        <Route
          exact
          path="/admin/login"
          element={
            <Suspense fallback={<Loading />}>
              <AdminLogin />
            </Suspense>
          }
        />
        <Route
          exact
          path="/vendor/login"
          element={
            <Suspense fallback={<Loading />}>
              <AdminLogin />
            </Suspense>
          }
        />
        <Route
          exact
          path="/teleuser/login"
          element={
            <Suspense fallback={<Loading />}>
              <AdminLogin />
            </Suspense>
          }
        />
        <Route
          exact
          path="/admin/*"
          element={
            path[1] === "admin" && !localStorage.getItem("adminToken") ? (
              <Navigate to="/admin/login" />
            ) : (
              <Suspense fallback={<Loading />}>
                <Admin />
              </Suspense>
            )
          }
        />
        <Route
          exact
          path="/vendor/*"
          element={
            path[1] === "vendor" && !localStorage.getItem("vendorToken") ? (
              <Navigate to="/vendor/login" />
            ) : (
              <Suspense fallback={<Loading />}>
                <Admin />
              </Suspense>
            )
          }
        />
        <Route
          exact
          path="/teleuser/*"
          element={
            path[1] === "teleuser" && !localStorage.getItem("teleToken") ? (
              <Navigate to="/teleuser/login" />
            ) : (
              <Suspense fallback={<Loading />}>
                <Admin />
              </Suspense>
            )
          }
        />
        <Route
          exact
          path="/*"
          element={
            <Suspense fallback={<Loading />}>
              <User />
            </Suspense>
          }
        />
      </Routes>
    </>
  );
};

export default RootRoutes;
