import React from "react";
import { useState } from "react";
import EventsContext from "./eventsContext";
const host = process.env.REACT_APP_HOST;
const events_DUMMY_DATA = {
  count: 8,
  events: [
    {
      _id: 1,
      name: "DJ Rhea Concert",
      club: "Asteria",
      date: "2022-07-28T06:43:47.738+00:00",
      category: "Concert",
      city: "Jaipur",
      area: "C-Scheme",
      pincode: "202004",
      status: true,
      countlikes: 23,
      coverImage: {
        _id: "1",
        location:
          "https://media.istockphoto.com/photos/cheering-crowd-of-unrecognized-people-at-a-rock-music-concert-concert-picture-id1189205501?k=20&m=1189205501&s=170667a&w=0&h=nBufa6S4f0D8ypZ6kqrWebQdFO6banzushXAHp0lpAc=",
      },
    },
  ],
};
// const event_DUMMY_DATA = {
//   _id: 1,
//   name: "DJ Rhea Concert",
//   clubName: "Asteria",
//   city: "Jaipur",
//   area: "C-Scheme",
//   pincode: "302001",
//   coordinates: "26.907193203243935,75.79518240012314",
//   category: "Happy Hours",
//   ageLimit: "18+",
//   date: "2022-07-28T06:43:47.738+00:00",
//   inTime: "2022-07-28T06:43:47.738+00:00",
//   outTime: "2022-07-28T07:43:47.738+00:00",
//   option1: {
//     title: "vip",
//     price: 300,
//     totalTickets: 5,
//     ticketsLeft: 50,
//     discountPercent: 5,
//   },
//   option2: {
//     title: "vip",
//     price: 300,
//     totalTickets: 5,
//     ticketsLeft: 50,
//     discountPercent: 5,
//   },
//   option3: {
//     title: "vip",
//     price: 300,
//     totalTickets: 5,
//     ticketsLeft: 50,
//     discountPercent: 5,
//   },
//   option4: {
//     title: "",
//     price: "",
//     totalTickets: "",
//     ticketsLeft: 0,
//     discountPercent: "",
//   },
//   option5: {
//     title: "",
//     price: "",
//     totalTickets: "",
//     ticketsLeft: 0,
//     discountPercent: "",
//   },
//   coverImgLink:
//     "https://media.istockphoto.com/photos/cheering-crowd-of-unrecognized-people-at-a-rock-music-concert-concert-picture-id1189205501?k=20&m=1189205501&s=170667a&w=0&h=nBufa6S4f0D8ypZ6kqrWebQdFO6banzushXAHp0lpAc=",
//   images: [
//     {
//       id: "1",
//       location:
//         "https://media.istockphoto.com/photos/cheering-crowd-of-unrecognized-people-at-a-rock-music-concert-concert-picture-id1189205501?k=20&m=1189205501&s=170667a&w=0&h=nBufa6S4f0D8ypZ6kqrWebQdFO6banzushXAHp0lpAc=",
//     },
//   ],
//   description:
//     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore deleniti, ex eius maxime, similique molestias hic eaque officiis ipsam illo amet delectus unde sed neque quis dolores voluptatem error. Sequi tempore voluptatem eveniet libero reiciendis suscipit cum nostrum tenetur totam, ipsam dolorum dicta omnis, sed cupiditate necessitatibus fugit corporis minus aperiam amet! Rerum fuga blanditiis omnis modi libero id quae, asperiores quos autem quisquam unde atque incidunt, animi sint. Non in nihil repellendus quod ratione excepturi quaerat perspiciatis, deleniti consequatur. Culpa debitis iure ab esse voluptate quis expedita accusantium dicta voluptatum modi voluptatem similique dolorem nihil, at sequi molestiae, dolores deserunt quisquam. Repellat adipisci nam est. Delectus provident placeat sequi dignissimos neque error consequatur quidem asperiores? Consequuntur ab ut alias nulla ipsa voluptatem laudantium in. Facere quisquam dolorum perferendis nesciunt aliquid commodi reprehenderit deleniti totam facilis animi voluptates provident repellat impedit autem ab iure iste, dolor voluptatem quaerat harum similique? Error, praesentium? Eaque voluptate, odit cupiditate ullam voluptatum sunt deserunt necessitatibus maiores alias! Dolores est quia ullam ex fuga amet minima dolor quidem pariatur doloribus provident, et, nihil rerum numquam alias sunt quisquam. Repellendus placeat eos iste repellat, adipisci minima distinctio ullam sequi iusto optio excepturi ad assumenda ratione rem?",
//   countlikes: 89,
// };
const EventsState = (props) => {
  // const evnetInitial = [];
  // const [event, setEvent] = useState(eventInitial)

  const clubEvents = {
    count: 8,
    events: [
      {
        id: 1,
        club: "Asteria",
        date: "2022-07-28T06:43:47.738+00:00",
        category: "Concert",
        image:
          "https://media.istockphoto.com/photos/cheering-crowd-of-unrecognized-people-at-a-rock-music-concert-concert-picture-id1189205501?k=20&m=1189205501&s=170667a&w=0&h=nBufa6S4f0D8ypZ6kqrWebQdFO6banzushXAHp0lpAc=",
      },
    ],
  };
  const [events, setEvents] = useState({
    count: 0,
    events: [],
  });
  const [eventlist, setEventList] = useState([]);

  const [event, setEvent] = useState({
    name: "",
    clubName: "",
    city: "",
    category: "",
    area: "",
    pincode: "",
    coordinates: "",
    categoryName: "",
    ageLimit: "",
    inTime: "",
    outTime: "",
    coverImgLink: "",
    images: [],
    description: "",
    option1: {
      title: "",
      price: "",
      totalTickets: "",
      ticketsLeft: 0,
      discountPercent: "",
    },
    option2: {
      title: "",
      price: "",
      totalTickets: "",
      ticketsLeft: 0,
      discountPercent: "",
    },
    option3: {
      title: "",
      price: "",
      totalTickets: "",
      ticketsLeft: 0,
      discountPercent: "",
    },
    option4: {
      title: "",
      price: "",
      totalTickets: "",
      ticketsLeft: 0,
      discountPercent: "",
    },
    option5: {
      title: "",
      price: "",
      totalTickets: "",
      ticketsLeft: 0,
      discountPercent: "",
    },
    datedUserId: "",
  });
  const fetchEvents = async () => {
    // setEvents(events_DUMMY_DATA);
    const response = await fetch(`${host}/event/getupcomingevents`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    setEvents(json);
  };
  const fetchEventLists = async () => {
    // setEvents(events_DUMMY_DATA);
    const response = await fetch(`${host}/event`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
      },
    });
    const json = await response.json();
    console.log(json);
    setEventList(json);
  };
  // const fetchAllEvents = () => {
  //   setEvents(events_DUMMY_DATA);
  // };
  const fetchEvent = async (id) => {
    // setEvent({ ...event_DUMMY_DATA, subtotal: 0 });
    const response = await fetch(`${host}/event/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    console.log(json);
    // setEvent(json)
    return json;
  };

 
  const deleteEvent = async (id) => {
    const response = await fetch(`${host}/event/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
      },
    });
    return response;
  };

  return (
    <EventsContext.Provider
      value={{
        events,
        eventlist,
        fetchEventLists,
        clubEvents,
        event,
        fetchEvent,
        fetchEvents,
        setEvent,
        deleteEvent,
      }}
    >
      {props.children}
    </EventsContext.Provider>
  );
};

export default EventsState;
